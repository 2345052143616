import React, { useEffect, useState } from 'react';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';
import {
  Card,
  Form,
  Divider,
  Typography,
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  Button,
  message,
  Checkbox,
  InputNumber,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import { States, State, Taxpayer } from 'sigt';
import { connect, useSelector } from 'react-redux';
import UserSelect from '../../Forms/components/UserSelect';
import moment from 'moment';
import MultipleTransactions from '../../Forms/components/MultipleTransactions';
import axios from 'axios';
import { round } from 'lodash';
const server = process.env.REACT_APP_SERVER_URL;

const SpecialSettlements: React.FC<SpecialSettlementsProps> = ({ thm, branches, institutions, auth }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [esVigente, setEsVigente] = useState(true);

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const [blockMonto, setBlockMonto] = useState(false);
  const [montod, setMontoD] = useState(1);

  const petro = useSelector((state: State) => state.coin.petro);

  const taxPayer: Taxpayer = (history.location.state as any)?.contribuyente || auth?.user?.contribuyente;
  const showAll = auth.user?.institucion?.id === 9;
  const cpuBranches = ['111', '123', '124'];

  useEffect(() => {
    if (auth?.user?.tipoUsuario === 4 && !id) {
      if (auth?.user?.contribuyente?.id) {
        history.push(`/dashboard/impuestos/especiales/${auth?.user?.contribuyente?.id}`);
      } else {
        history.push('/dashboard');
      }
    }
  }, []);

  useEffect(() => {
    if (auth?.user?.tipoUsuario !== 4) {
      setEsVigente(false);
    }
  }, []);

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/impuestos/especiales');
    }
    if (auth.user?.institucion?.nombreCorto !== 'SAGAS' && auth.user?.institucion?.nombreCompleto !== 'CPU') {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if ( auth?.user?.tipoUsuario === 4 && !id) {
  //     if( auth?.user?.contribuyente?.id) {
  //       history.push(`/dashboard/impuestos/especiales/${auth?.user?.contribuyente?.id}`);
  //     }
  //     else{
  //       history.push('/dashboard');
  //     }
  //   }
  // },[])

  useEffect(() => {
    if (data.ramo) {
      let bran = branches.find((b) => b.id === data.ramo);
      if (data.subramo) {
        let subr = bran?.subramos?.find((s) => s.id === data.subramo);
        form.setFieldsValue({ monto: subr?.monto && subr?.monto > 0 ? round(subr?.monto, 2) : 0 });
        setBlockMonto(subr?.monto && subr?.monto > 0 ? true : false);
      } else {
        if ((bran?.subramos?.length || 0) > 0 && bran?.monto && bran?.monto > 0) {
          form.setFieldsValue({ monto: bran?.monto });
          setBlockMonto(true);
        } else {
          if (bran?.descripcion.includes('%')) {
            const montoRamo = bran?.descripcion?.match(/\d+/g) ?? 0;
            form.setFieldsValue({
              monto: bran?.monto ? round(montod * (Number(montoRamo) / 100), 2) : 0,
            });
            setBlockMonto(true);
          } else {
            form.setFieldsValue({
              monto: bran?.monto && Number(bran?.monto) > 0 ? round(Number(bran?.monto), 2) : 0,
            });
            setBlockMonto(bran?.monto && bran?.monto > 0 ? true : false);
          }
        }
      }
    }
  }, [branches, data.ramo, data.subramo, montod]);

  useEffect(() => {
    form.setFieldsValue({ subramo: '' });
    setData({ ...data, subramo: '' });
    resetCounters();
  }, [form.getFieldValue('ramo'), data.ramo]);

  const resetCounters = () => {
    form.setFieldsValue({ montod: '' });
    setMontoD(0);
  };

  const submit = async () => {
    const values = await form.validateFields();
    const ingresado = esVigente ? 0 : values.pagos.reduce((prev, current) => prev + current.costo, 0);
    if (!esVigente && (values.monto > ingresado || values.monto < ingresado)) {
      message.error('El monto de los pagos debe ser igual al monto de la liquidación especial');
    } else {
      values.fechaCancelada = {
        month: values.fechaCancelada.locale('es').format('MMMM'),
        year: parseInt(values.fechaCancelada.locale('es').format('YYYY')),
      };
      values.monto = +(+values.monto / petro).toFixed(8);
      values.impuestos = [
        { fechaCancelada: values.fechaCancelada, ramo: values.ramo, subramo: values.subramo, monto: values.monto },
      ];
      values.tipoDocumento = taxPayer.tipoDocumento;
      values.documento = taxPayer.documento;
      values.tipoContribuyente = taxPayer.tipoContribuyente;
      if (!esVigente) {
        values.pagos
          .filter((p) => p)
          .forEach((p) => {
            Object.keys(p).forEach((k) => {
              if (moment.isMoment(p[k])) {
                p[k] = p[k].toISOString();
              }
            });
          });
      }
      values.esVigente = esVigente;
      setLoading(true);
      try {
        const response = await axios.post(`${server}/settlements/special`, values, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.success('Liquidación creada de manera exitosa');

        if (auth?.user?.tipoUsuario !== 4) history.push('/dashboard/impuestos/especiales');
        else history.push('/dashboard');

        if (response.status === 201 && response.data.solicitud.recibo) {
          const win = window.open(response.data.solicitud.recibo, '_blank');
          win?.focus();
        }
        form.resetFields();
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al crear liquidación');
      } finally {
        setLoading(false);
      }
    }
  };

  const selectOfficial = (
    <Select
      placeholder='Seleccione un ramo'
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option!.children.props.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {showAll
        ? branches.map((b) => (
            <Select.Option value={b.id} key={b.id}>
              <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{b.descripcion}</div>
            </Select.Option>
          ))
        : branches
            .filter((b) => cpuBranches.includes(b.codigo || ''))
            .filter((b) => b.liquidacionEspecial)
            .map((b) => (
              <Select.Option value={b.id} key={b.id}>
                <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{b.descripcion}</div>
              </Select.Option>
            ))}
    </Select>
  );

  const selectExternal = (
    <Select placeholder='Seleccione un ramo'>
      {branches
        .filter((b) => [219, 220, 227].includes(Number(b.id)))
        .map((b) => (
          <Select.Option value={b.id} key={b.id}>
            <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{b.descripcion}</div>
          </Select.Option>
        ))}
    </Select>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title='Liquidaciones de Tasas e Impuestos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetTaxpayerInfo />}
      {id && (
        <Form
          form={form}
          layout='vertical'
          onFinish={submit}
          scrollToFirstError
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData(v);
          }}
        >
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Usuario de S.U.T.
            </Typography.Title>
          </Divider>
          <UserSelect
            users={
              auth.user?.tipoUsuario === 4 ? [{ id: auth.user.id, correo: auth.user.nombreUsuario }] : taxPayer?.usuarios || []
            }
            form={form}
          />
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Referencia Municipal (R.I.M.)
              </Typography.Title>
            </Divider>
          )}
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Row gutter={24}>
              <Col xs={24} xl={8}>
                <Form.Item
                  label='Referencia Municipal (Si posee)'
                  name='rim'
                  rules={[{ required: false, message: 'Debe ingresar la referencia municipal' }]}
                >
                  <Select placeholder='Seleccione la referencia municipal'>
                    {taxPayer?.sucursales?.map((s) => (
                      <Select.Option value={s.referenciaMunicipal} key={s.id}>
                        {s.referenciaMunicipal}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Liquidación de Tasas e Impuestos
            </Typography.Title>
          </Divider>
          <Row gutter={24}>
            <Col xs={24} xl={8}>
              <Form.Item label='Ramo' name='ramo' rules={[{ required: true, message: 'Debe ingresar el ramo' }]}>
                {auth.user?.tipoUsuario === 4 ? selectExternal : selectOfficial}
              </Form.Item>
            </Col>
            {data.ramo &&
              (branches
                .find((b) => b.id === data.ramo)
                ?.subramos?.filter(
                  (s) =>
                    !s.descripcion.includes('GTIC') && !s.descripcion.includes('Convenio') && !s.descripcion.includes('ordinario')
                )?.length || 0) > 1 && (
                <Col xs={24} xl={8}>
                  <Form.Item label='Subramo' name='subramo' rules={[{ required: true, message: 'Debe ingresar el ramo' }]}>
                    <Select placeholder='Seleccione un subramo'>
                      {branches
                        .find((b) => b.id === data.ramo)
                        ?.subramos?.filter(
                          (s) =>
                            !s.descripcion.includes('GTIC') &&
                            !s.descripcion.includes('Convenio') &&
                            !s.descripcion.includes('ordinario')
                        )
                        .map((b) => (
                          <Select.Option value={b.id} key={b.id}>
                            {b.descripcion}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            <Col xs={24} xl={8}>
              <Form.Item
                label='Fecha de Liquidación'
                name='fechaCancelada'
                rules={[{ required: true, message: 'Debe ingresar la fecha de liquidación' }]}
              >
                <DatePicker
                  format='DD/MM/YYYY'
                  placeholder='Seleccione la fecha'
                  disabledDate={(current) => current > moment().endOf('day')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            {data.ramo && branches.find((b) => b.id === data.ramo)?.descripcion?.includes('%') && (
              <Col xs={24} xl={8}>
                <Form.Item
                  label='Monto a declarar'
                  name='montod'
                  rules={[{ required: true, message: 'Debe ingresar el monto a declarar' }]}
                >
                  <InputNumber
                    placeholder='Monto a declarar'
                    onChange={(value) => setMontoD(value || 0)}
                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                    style={{ width: '100%', fontSize: 18 }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} xl={8}>
              <Form.Item
                label='Monto a pagar'
                name='monto'
                rules={[{ required: true, message: 'Debe ingresar el monto de la liquidación' }]}
              >
                <InputNumber
                  placeholder='Monto'
                  disabled={blockMonto}
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  style={{ width: '100%', fontSize: 18 }}
                />
              </Form.Item>
            </Col>
          </Row>
          {showAll && (
            <Checkbox checked={!esVigente} onChange={(e) => setEsVigente(!e.target.checked)}>
              Incluir pago de la liquidación
            </Checkbox>
          )}
          {!esVigente && showAll && (
            <>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Datos de Pago
                </Typography.Title>
              </Divider>
              <MultipleTransactions
                selectedInstitution={institutions?.find((i) => i.id === 9)}
                form={form}
                data={data}
                maxCred={0}
              />
            </>
          )}
          <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Button
                onClick={() =>
                  history.push('/dashboard/impuestos/especiales', {
                    contribuyentes: (history.location.state as any)?.contribuyentes,
                  })
                }
              >
                Atrás
              </Button>
            </Col>
            <Row gutter={12}>
              <Col>
                <Button loading={loading} type='primary' onClick={form.submit}>
                  Crear liquidación
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  branches: state.brch.branches,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps)(SpecialSettlements);

interface SpecialSettlementsProps {
  thm: States.ThemeColors;
  branches: States.Branches['branches'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
}
